import { graphql, useStaticQuery } from "gatsby"
//allStrapiAuditorios(sort: {order: ASC, fields: orden}) {

const usePaises = () => {
  const resultado = useStaticQuery(
    graphql`
      query {
        allStrapiPaises {
          nodes {
            alfa2
            mostrar
            nombre_pais
            nombre {
              en
              es
              pt
            }
          }
        }
      }
    `
  )
  return resultado.allStrapiPaises.nodes.map(pais => ({
    nombre: pais.nombre,
    mostrar: pais.mostrar,
    alfa2: pais.alfa2,
    nombre_pais: pais.nombre_pais,
  }))
}

export default usePaises
