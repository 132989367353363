import { graphql, useStaticQuery } from "gatsby"
//allStrapiAuditorios(sort: {order: ASC, fields: orden}) {

const useLocalidades = () => {
  const resultado = useStaticQuery(
    graphql`
      query {
        allStrapiLocalidades(sort: {order: ASC, fields: nombre}) {
        # allStrapiLocalidades(sort: {order: ASC, fields: orden}) {
          nodes {
            mostrar
            nombre
            orden
            provincia{
              nombre
            }
          }
        }
      }
    `
  )
  return resultado.allStrapiLocalidades.nodes.map(localidad => ({
    nombre: localidad.nombre,
    mostrar: localidad.mostrar,
    orden: localidad.orden,
    provincia: localidad.provincia.nombre,
  }))
}

export default useLocalidades
