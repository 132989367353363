import React, { useState, useContext, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import firebase from "gatsby-plugin-firebase"
import { AuthContext } from "../context/auth"
import publicIp from "public-ip"
import { injectIntl, Link, navigate } from "gatsby-plugin-intl"
import axios from "axios"
import {
  Button,
  Select,
  InputLabel,
  MenuItem,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core"
import { graphql } from "gatsby"
import usePaises from "../hooks/usePaises"
import useProvincias from "../hooks/useProvincias"
import useLocalidades from "../hooks/useLocalidades"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: "none",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: 8,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: 8,
  },
}))

const Registro = ({ intl, data }) => {
  const {
    nombre,
    bienvenida,
    link_planilla_registro,
    ingreso_habilitado,
    alert_ingreso_habilitado,
    boton_registrarme,
    boton_volver_login,
    label_nombre,
    label_apellido,
    label_email,
    label_clave,
    label_profesion,
    label_provincia,
    label_localidad,
    label_telefono,
    label_pais,
    alert_campos_obligatorios,
    label_ocupacion_dirigente_entidad,
    label_ocupacion_empresario_rubro,
    label_ocupacion_empresario_empresa,
    label_ocupacion_otro,
    label_ocupacion_dirigente,
    label_ocupacion_empresario,
    label_dni,
    label_nacimiento,
    label_ocupacion,
  } = data.strapiRegistro

  const currentLocale = intl.locale
  const [pais, setPais] = useState("")
  const [provincia, setProvincia] = useState("")
  const [localidad, setLocalidad] = useState("")
  const [ocupacion, setOcupacion] = useState("")

  const paises = usePaises()
  const provincias = useProvincias()
  const localidades = useLocalidades()

  paises.sort(function (a, b) {
    let left = a.nombre_pais
    let right = b.nombre_pais
    return left === right ? 0 : left > right ? 1 : -1
  })
  const pad = function (i) {
    return i < 10 ? "0" + i : i
  }
  let today = new Date()
  let date =
    today.getFullYear() +
    "-" +
    pad(today.getMonth() + 1) +
    "-" +
    pad(today.getDate())

  const [ramo, setRamo] = useState("")

  const handleChangeRamo = event => {
    setRamo(event.target.value)
  }

  const handleChangePais = event => {
    setProvincia("")
    setPais(event.target.value)
  }
  const handleChangeProvincia = event => {
    setLocalidad("")
    setProvincia(event.target.value)
  }
  const handleChangeLocalidad = event => {
    setLocalidad(event.target.value)
  }
  const handleChangeOcupacion = event => {
    setOcupacion(event.target.value)
  }

  const [dataRegistro, setDataRegistro] = useState({
    email: "",
    password: "",
    error: null,
    nombre: "",
    apellido: "",
    telefono: "",
    dni: "",
    ocupacion_otro: "",
    ocupacion_empresario_empresa: "",
    fecha_nacimiento: date,
    camara_entidad: "",
  })

  const { user, setUser } = useContext(AuthContext)
  const [btnDisabled, setBtnDisabled] = useState(false)

  const handleChange = e => {
    setDataRegistro({ ...dataRegistro, [e.target.name]: e.target.value })
  }
  const CodigoPais = pais => {
    let alfa2
    paises.filter(function (paisFiltrado) {
      if (
        paisFiltrado.nombre.es.toLowerCase().includes(pais.toLowerCase()) ||
        paisFiltrado.nombre.en.toLowerCase().includes(pais.toLowerCase()) ||
        paisFiltrado.nombre.pt.toLowerCase().includes(pais.toLowerCase())
      ) {
        if (
          paisFiltrado.nombre.es.toLowerCase() === pais.toLowerCase() ||
          paisFiltrado.nombre.en.toLowerCase() === pais.toLowerCase() ||
          paisFiltrado.nombre.pt.toLowerCase() === pais.toLowerCase()
        ) {
          alfa2 = paisFiltrado.alfa2
        }
      }
    })
    return alfa2
  }
  /****************
   * codigo pais fin
   */

  const handleSubmit = async e => {
    e.preventDefault()
    setDataRegistro({ ...dataRegistro, error: null })
    if (
      dataRegistro.nombre !== "" &&
      dataRegistro.apellido !== "" &&
      dataRegistro.email !== "" &&
      dataRegistro.password !== "" &&
      dataRegistro.telefono !== "" &&
      pais !== ""
    ) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(
          dataRegistro.email.trim(),
          dataRegistro.password
        )
        .then(result2 => {
          setUser(result2.user)
          setBtnDisabled(true)

          grabarPlanilla()

          // result2.user
          //   .sendEmailVerification()
          //   .then(function () {
          //     // Email sent.
          //     alert(
          //       "Por favor verifique su email y haga click en el vinculo para poder ingresar"
          //     )
          //   })
          //   .catch(function (error) {
          //     // An error happened.
          //   })
        })
        .catch(function (error) {
          error.message ===
          "The email address is already in use by another account."
            ? alert(
                currentLocale === "en"
                  ? "The email address is already in use by another account."
                  : currentLocale === "pt"
                  ? "Este e-mail já está registado"
                  : "Este correo electrónico ya está registrado."
              )
            : error.message === "The email address is badly formatted."
            ? alert(
                currentLocale === "en"
                  ? "The email address is badly formatted."
                  : currentLocale === "pt"
                  ? "O e-mail informado não é um e-mail válido! Revise os dados inseridos."
                  : "El e-mail ingresado no es un e-mail válido! Revisar los datos ingresados."
              )
            : error.message === "Password should be at least 6 characters"
            ? alert(
                currentLocale === "en"
                  ? "Password should be at least 6 characters"
                  : currentLocale === "pt"
                  ? "Password should be at least 6 characters"
                  : "La clave no puede ser inferior a 6 caracteres"
              )
            : alert(`Comunique el error al proveedor: ${error.message}`)
        })
    } else {
      alert(
        currentLocale === "en"
          ? alert_campos_obligatorios.en
          : currentLocale === "pt"
          ? alert_campos_obligatorios.pt
          : alert_campos_obligatorios.es
      )
    }
  }
  // para que no hagan log de 2 ip diferentes
  const getClientIp = async () =>
    await publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    })

  const [ip, setIp] = useState("")
  useEffect(() => {
    getClientIp().then(result => setIp(result))
  }, [])

  const docRef = firebase.firestore().collection("usuarios")

  useEffect(() => {
    if (user != null) {
      firebase
        .firestore()
        .collection("usuarios")
        .doc(user.uid)
        .get()
        .then(value => {
          if (value.exists) {
            navigate("/inicio")
          } else {
            let guardarDocumento = {
              nombre: dataRegistro.nombre.trim(),
              apellido: dataRegistro.apellido.trim(),
              email: dataRegistro.email.toLowerCase().trim(),
              pais: pais,
              codpais: CodigoPais(pais),
              telefono: dataRegistro.telefono,
              rubro: ramo,
              camara_entidad: dataRegistro.camara_entidad,
              ip: ip,
              uid: user.uid,
              dni: dataRegistro.dni.trim(),
              localidad: localidad,
              provincia: provincia,
              ocupacion: ocupacion,
              fecha_nacimiento: dataRegistro.fecha_nacimiento.trim(),
              ocupacion_otro: dataRegistro.ocupacion_otro,
              ocupacion_empresario_empresa:
                dataRegistro.ocupacion_empresario_empresa,
            }
            docRef
              .doc(user.uid)
              .set(guardarDocumento)
              .then(res => {
                user
                  .updateProfile({
                    displayName: `${dataRegistro.nombre.trim()} ${dataRegistro.apellido.trim()}`,
                  })
                  .then(function () {
                    // console.log("ingreso_habilitado", ingreso_habilitado)
                    if (ingreso_habilitado) {
                      navigate("/inicio")
                    } else {
                      firebase.auth().signOut() //ACTIVAR PARA LA CONFIRMACION DEL EMAIL
                      alert(
                        currentLocale === "en"
                          ? alert_ingreso_habilitado.en
                          : currentLocale === "pt"
                          ? alert_ingreso_habilitado.pt
                          : alert_ingreso_habilitado.es
                      )
                      navigate("/") //ACTIVAR PARA LA CONFIRMACION DEL EMAIL
                    }
                  })
                  .catch(function (error) {})
              })
          }
        })
    }
  }, [user])

  const grabarPlanilla = () => {
    axios.post(link_planilla_registro, {
      nombre: dataRegistro.nombre.trim(),
      apellido: dataRegistro.apellido.trim(),
      email: dataRegistro.email.toLowerCase().trim(),
      dni: dataRegistro.dni.trim(),
      camara_entidad: dataRegistro.camara_entidad,
      rubro: ramo,
      localidad: localidad,
      provincia: provincia,
      fecha_nacimiento: dataRegistro.fecha_nacimiento.trim(),
      ocupacion: ocupacion,
      pais: pais,
      telefono: dataRegistro.telefono.trim(),
      ocupacion_otro: dataRegistro.ocupacion_otro,
      fecha: Date.now().toString(),
      ocupacion_empresario_empresa: dataRegistro.ocupacion_empresario_empresa,
    })
    /*     axios.post(
      "https://servidor.virtualeventweb.com:1340/plataforma-registros",
      {
        nombre: dataRegistro.nombre.trim(),
        apellido: dataRegistro.apellido.trim(),
        email: dataRegistro.email.toLowerCase().trim(),
        dni: dataRegistro.dni.trim(),
              camara_entidad: dataRegistro.camara_entidad,
        rubro: ramo,
        localidad: localidad,
        provincia: provincia,
        ocupacion_otro: dataRegistro.ocupacion_otro,
        fecha_nacimiento: dataRegistro.fecha_nacimiento.trim(),
        pais: pais,
        ocupacion_empresario_empresa: dataRegistro.ocupacion_empresario_empresa,
        telefono: dataRegistro.telefono.trim(),
        fecha: Date.now().toString(),
      }
    ) */
  }

  const classes = useStyles()

  const ramos_es = [
    "Industria",
    "Automotor",
    "Aviación y aeroespacial",
    "Materiales de construcción y construcción",
    "Tecnología limpia",
    "Bienes de consumo",
    "Defender y Homeland Security",
    "Educación",
    "Servicio Financiero y Profesional",
    "Alimentos / Agricultura",
    "Tecnologías de la información",
    "Logística, distribución e infraestructura",
    "Ciencias de la vida y tecnología médica",
    "Fabricación",
    "Embarcaciones y equipos marinos",
    "Otro",
  ]
  const ramos_en = [
    "Industry",
    "Automotive",
    "Aviation and aerospace",
    "Building and construction materials",
    "Clean technology",
    "Consumer goods",
    "Defender and Homeland Security",
    "Education",
    "Financial and Professional Service",
    "Food / Agriculture",
    "Information technology",
    "Logistics, distribution, and infrastructure",
    "Life Sciences and Medical Technology",
    "Manufacturing",
    "Marine vessels and equipment",
    "Other",
  ]
  const ramos_pt = [
    "Indústria",
    "Automotivo",
    "Aviação e aeroespacial",
    "Construção e materiais de construção",
    "Tecnologia limpa",
    "Bens de consumo",
    "Defender e Segurança Interna",
    "Educação",
    "Serviço Financeiro e Profissional",
    "Alimentos / Agricultura",
    "Tecnologia da Informação",
    "Logística, distribuição e infraestrutura",
    "Ciências da Vida e Tecnologia Médica",
    "Manufatura",
    "Embarcações e equipamentos marítimos",
    "Outro",
  ]
  const ramos =
    currentLocale === "en"
      ? ramos_en
      : currentLocale === "pt"
      ? ramos_pt
      : ramos_es

  return (
    <Layout>
      <SEO
        title={
          currentLocale === "en"
            ? nombre.en
            : currentLocale === "pt"
            ? nombre.pt
            : nombre.es
        }
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            style={{ textAlign: "center" }}
          >
            {currentLocale === "en"
              ? bienvenida.en
              : currentLocale === "pt"
              ? bienvenida.pt
              : bienvenida.es}
          </Typography>

          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="nombre"
              label={
                currentLocale === "en"
                  ? label_nombre.en
                  : currentLocale === "pt"
                  ? label_nombre.pt
                  : label_nombre.es
              }
              type="text"
              id="nombre"
              value={dataRegistro.nombre}
              onChange={handleChange}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="apellido"
              label={
                currentLocale === "en"
                  ? label_apellido.en
                  : currentLocale === "pt"
                  ? label_apellido.pt
                  : label_apellido.es
              }
              type="text"
              id="apellido"
              value={dataRegistro.apellido}
              onChange={handleChange}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={
                currentLocale === "en"
                  ? label_email.en
                  : currentLocale === "pt"
                  ? label_email.pt
                  : label_email.es
              }
              name="email"
              autoComplete="email"
              //   autoFocus
              value={dataRegistro.email}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={
                currentLocale === "en"
                  ? label_clave.en
                  : currentLocale === "pt"
                  ? label_clave.pt
                  : label_clave.es
              }
              type="password"
              id="password"
              autoComplete="current-password"
              value={dataRegistro.password}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              name="dni"
              label={
                currentLocale === "en"
                  ? label_dni.en
                  : currentLocale === "pt"
                  ? label_dni.pt
                  : label_dni.es
              }
              type="text"
              id="DNI"
              value={dataRegistro.dni}
              onChange={handleChange}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="fecha_nacimiento"
              label={
                currentLocale === "en"
                  ? label_nacimiento.en
                  : currentLocale === "pt"
                  ? label_nacimiento.pt
                  : label_nacimiento.es
              }
              type="date"
              id="fecha_nacimiento"
              value={dataRegistro.fecha_nacimiento}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              name="telefono"
              label={
                currentLocale === "en"
                  ? label_telefono.en
                  : currentLocale === "pt"
                  ? label_telefono.pt
                  : label_telefono.es
              }
              type="tel"
              id="telefono"
              value={dataRegistro.telefono}
              onChange={handleChange}
            />

            <InputLabel id="ocupacionLabel" style={{ padding: 15 }}>
              {currentLocale === "en"
                ? label_ocupacion.en
                : currentLocale === "pt"
                ? label_ocupacion.pt
                : label_ocupacion.es}
            </InputLabel>
            <Select
              style={{ width: "100%", padding: 10 }}
              id="ocupacion"
              value={ocupacion}
              onChange={handleChangeOcupacion}
            >
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"empresario"}>
                {currentLocale === "en"
                  ? label_ocupacion_empresario.en
                  : currentLocale === "pt"
                  ? label_ocupacion_empresario.pt
                  : label_ocupacion_empresario.es}
              </MenuItem>
              <MenuItem value={"dirigente"}>
                {currentLocale === "en"
                  ? label_ocupacion_dirigente.en
                  : currentLocale === "pt"
                  ? label_ocupacion_dirigente.pt
                  : label_ocupacion_dirigente.es}
              </MenuItem>
              <MenuItem value={"otro"}>
                {currentLocale === "en"
                  ? label_ocupacion_otro.en
                  : currentLocale === "pt"
                  ? label_ocupacion_otro.pt
                  : label_ocupacion_otro.es}
              </MenuItem>
            </Select>

            {ocupacion === "otro" ? (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="ocupacion_otro"
                label={
                  currentLocale === "en"
                    ? label_ocupacion_otro.en
                    : currentLocale === "pt"
                    ? label_ocupacion_otro.pt
                    : label_ocupacion_otro.es
                }
                type="text"
                id="ocupacion_otro"
                value={dataRegistro.ocupacion_otro}
                onChange={handleChange}
              />
            ) : ocupacion === "empresario" ? (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="ocupacion_empresario_empresa"
                  label={
                    currentLocale === "en"
                      ? label_ocupacion_empresario_empresa.en
                      : currentLocale === "pt"
                      ? label_ocupacion_empresario_empresa.pt
                      : label_ocupacion_empresario_empresa.es
                  }
                  type="text"
                  id="ocupacion_empresario_empresa"
                  value={dataRegistro.ocupacion_empresario_empresa}
                  onChange={handleChange}
                />
                <InputLabel id="ramosLabel" style={{ padding: 5 }}>
                  {currentLocale === "en"
                    ? label_ocupacion_empresario_rubro.en
                    : currentLocale === "pt"
                    ? label_ocupacion_empresario_rubro.pt
                    : label_ocupacion_empresario_rubro.es}
                </InputLabel>
                <Select
                  style={{ width: "100%", padding: 5 }}
                  id="categoria"
                  value={ramo}
                  onChange={handleChangeRamo}
                >
                  {ramos.map(ramo => (
                    <MenuItem value={ramo}>{ramo}</MenuItem>
                  ))}
                </Select>
              </>
            ) : ocupacion === "dirigente" ? (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="camara_entidad"
                  label={
                    currentLocale === "en"
                      ? label_ocupacion_dirigente_entidad.en
                      : currentLocale === "pt"
                      ? label_ocupacion_dirigente_entidad.pt
                      : label_ocupacion_dirigente_entidad.es
                  }
                  type="text"
                  id="camara_entidad"
                  value={dataRegistro.camara_entidad}
                  onChange={handleChange}
                />
              </>
            ) : (
              ""
            )}

            <InputLabel id="paisLabel" style={{ padding: 15 }}>
              {currentLocale === "en"
                ? label_pais.en
                : currentLocale === "pt"
                ? label_pais.pt
                : label_pais.es}
            </InputLabel>
            <Select
              style={{ width: "100%", padding: 10 }}
              id="pais"
              value={pais}
              onChange={handleChangePais}
            >
              {paises.map(pais =>
                pais.mostrar ? (
                  <MenuItem value={pais.nombre.es}>
                    <>
                      {currentLocale === "en"
                        ? pais.nombre.en
                        : currentLocale === "pt"
                        ? pais.nombre.pt
                        : pais.nombre.es}
                    </>
                  </MenuItem>
                ) : (
                  ""
                )
              )}
            </Select>
            {pais === "Argentina" ? (
              <>
                <InputLabel id="provinciaLabel" style={{ padding: 15 }}>
                  {currentLocale === "en"
                    ? label_provincia.en
                    : currentLocale === "pt"
                    ? label_provincia.pt
                    : label_provincia.es}
                </InputLabel>
                <Select
                  style={{ width: "100%", padding: 10 }}
                  id="provincia"
                  value={provincia}
                  onChange={handleChangeProvincia}
                >
                  {provincias.map(provincia =>
                    provincia.mostrar && provincia.pais === pais ? (
                      <MenuItem value={provincia.nombre}>
                        <>
                          {currentLocale === "en"
                            ? provincia.nombre
                            : currentLocale === "pt"
                            ? provincia.nombre
                            : provincia.nombre}
                        </>
                      </MenuItem>
                    ) : (
                      ""
                    )
                  )}
                </Select>
              </>
            ) : pais !== "" ? (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="provincia"
                label={
                  currentLocale === "en"
                    ? label_provincia.en
                    : currentLocale === "pt"
                    ? label_provincia.pt
                    : label_provincia.es
                }
                type="text"
                id="provincia"
                value={provincia}
                onChange={handleChangeProvincia}
              />
            ) : (
              ""
            )}
            {pais === "Argentina" && provincia !== "" ? (
              <>
                <InputLabel id="localidadLabel" style={{ padding: 15 }}>
                  {currentLocale === "en"
                    ? label_localidad.en
                    : currentLocale === "pt"
                    ? label_localidad.pt
                    : label_localidad.es}
                </InputLabel>
                <Select
                  style={{ width: "100%", padding: 10 }}
                  //labelId="pais"
                  id="localidad"
                  value={localidad}
                  onChange={handleChangeLocalidad}
                >
                  {localidades.map(localidad =>
                    localidad.mostrar && localidad.provincia === provincia ? (
                      <MenuItem value={localidad.nombre}>
                        <>
                          {currentLocale === "en"
                            ? localidad.nombre
                            : currentLocale === "pt"
                            ? localidad.nombre
                            : localidad.nombre}
                        </>
                      </MenuItem>
                    ) : (
                      ""
                    )
                  )}
                </Select>{" "}
              </>
            ) : pais !== "" && provincia !== "" ? (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="localidad"
                label={
                  currentLocale === "en"
                    ? label_localidad.en
                    : currentLocale === "pt"
                    ? label_localidad.pt
                    : label_localidad.es
                }
                type="text"
                id="localidad"
                value={localidad}
                onChange={handleChangeLocalidad}
              />
            ) : (
              ""
            )}
            {dataRegistro.error ? (
              <p style={{ color: "red" }}>{dataRegistro.error}</p>
            ) : null}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={btnDisabled}
              className={classes.submit}
              style={{
                background: `${boton_registrarme.color_fondo}`,
                color: `${boton_registrarme.color_letra}`,
              }}
            >
              {currentLocale === "en"
                ? boton_registrarme.en
                : currentLocale === "pt"
                ? boton_registrarme.pt
                : boton_registrarme.es}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to={"/"} className={classes.link}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                    disabled={btnDisabled}
                    style={{
                      background: `${boton_volver_login.color_fondo}`,
                      color: `${boton_volver_login.color_letra}`,
                    }}
                  >
                    {currentLocale === "en"
                      ? boton_volver_login.en
                      : currentLocale === "pt"
                      ? boton_volver_login.pt
                      : boton_volver_login.es}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </Layout>
  )
}
/* 

               */

export default injectIntl(Registro)

export const query = graphql`
  query {
    strapiRegistro {
      alert_campos_obligatorios {
        es
        en
        pt
      }
      label_dni {
        es
        en
        pt
      }
      label_nacimiento {
        es
        en
        pt
      }
      label_ocupacion {
        es
        en
        pt
      }
      label_ocupacion_empresario {
        es
        en
        pt
      }
      label_ocupacion_dirigente {
        es
        en
        pt
      }
      label_ocupacion_otro {
        es
        en
        pt
      }
      label_ocupacion_empresario_empresa {
        es
        en
        pt
      }
      label_ocupacion_empresario_rubro {
        es
        en
        pt
      }
      label_ocupacion_dirigente_entidad {
        es
        en
        pt
      }
      label_pais {
        es
        en
        pt
      }
      label_telefono {
        es
        en
        pt
      }
      label_profesion {
        es
        en
        pt
      }
      label_clave {
        es
        en
        pt
      }
      label_email {
        es
        en
        pt
      }
      label_apellido {
        es
        en
        pt
      }
      label_nombre {
        es
        en
        pt
      }
      label_provincia {
        es
        en
        pt
      }
      label_localidad {
        es
        en
        pt
      }
      nombre {
        es
        en
        pt
      }
      boton_volver_login {
        es
        en
        pt
        color_fondo
        color_letra
      }
      boton_registrarme {
        es
        en
        pt
        color_fondo
        color_letra
      }
      ingreso_habilitado
      alert_ingreso_habilitado {
        es
        en
        pt
      }
      link_planilla_registro
      bienvenida {
        es
        en
        pt
      }
    }
  }
`
