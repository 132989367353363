import { graphql, useStaticQuery } from "gatsby"
//allStrapiAuditorios(sort: {order: ASC, fields: orden}) {

const useProvincias = () => {
  const resultado = useStaticQuery(
    graphql`
      query {
        allStrapiProvincias(sort: {order: ASC, fields: nombre}) {
        # allStrapiProvincias(sort: {order: ASC, fields: orden}) {
          nodes {
            mostrar
            nombre
            orden
            pais{
              nombre_pais
            }
          }
        }
      }
    `
  )
  return resultado.allStrapiProvincias.nodes.map(provincia => ({
    nombre: provincia.nombre,
    mostrar: provincia.mostrar,
    orden: provincia.orden,
    pais: provincia.pais.nombre_pais,
  }))
}

export default useProvincias
